import React, { useState, createContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import { ThemeProvider } from './contexts/ThemeContext';
import Sidebar from './components/layout/Sidebar';
import Header from './components/layout/Header';

// Create context for sidebar state
export const SidebarContext = createContext();

// Lazy load components for better performance
const TrendingMovies = React.lazy(() => import('./components/TrendingMovies'));
const Genres = React.lazy(() => import('./components/Genres'));
const Search = React.lazy(() => import('./components/Search'));
const MovieDetails = React.lazy(() => import('./components/MovieDetails'));

// PUBLIC_INTERFACE
function App() {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <ThemeProvider>
      <Router>
        <SidebarContext.Provider value={{ isCollapsed, setIsCollapsed }}>
          <Box sx={{ display: 'flex' }}>
            <Sidebar />
            <Box 
              component="main" 
              sx={{ 
                flexGrow: 1, 
                ml: { 
                  xs: 0,
                  sm: isCollapsed ? '65px' : '240px'
                },
                transition: theme => theme.transitions.create('margin', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
              }}
            >
            <Header />
            <React.Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<TrendingMovies />} />
                <Route path="/genres" element={<Genres />} />
                <Route path="/search" element={<Search />} />
                <Route path="/movie/:id" element={<MovieDetails />} />
                <Route path="*" element={<div>Page not found</div>} />
              </Routes>
            </React.Suspense>
            </Box>
          </Box>
        </SidebarContext.Provider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
