import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Paper } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

// Static movie data for the slideshow
const staticMovies = [
  {
    id: 1,
    title: 'Inception',
    poster: 'https://image.tmdb.org/t/p/w500/9gk7adHYeDvHkCSEqAvQNLV5Uge.jpg',
    rating: 8.8
  },
  {
    id: 2,
    title: 'The Dark Knight',
    poster: 'https://image.tmdb.org/t/p/w500/qJ2tW6WMUDux911r6m7haRef0WH.jpg',
    rating: 9.0
  },
  {
    id: 3,
    title: 'Pulp Fiction',
    poster: 'https://image.tmdb.org/t/p/w500/d5iIlFn5s0ImszYzBPb8JPIfbXD.jpg',
    rating: 8.9
  },
  {
    id: 4,
    title: 'The Matrix',
    poster: 'https://image.tmdb.org/t/p/w500/f89U3ADr1oiB1s9GkdPOEpXUk5H.jpg',
    rating: 8.7
  },
  {
    id: 5,
    title: 'Interstellar',
    poster: 'https://image.tmdb.org/t/p/w500/gEU2QniE6E77NI6lCU6MxlNBvIx.jpg',
    rating: 8.6
  }
];

const SlideContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '250px', // Default desktop height
  overflow: 'hidden',
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.down('md')]: {
    height: '200px', // Tablet height
  },
  [theme.breakpoints.down('sm')]: {
    height: '180px', // Mobile height
  },
}));

const Slide = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'transform 0.5s ease-in-out',
}));

const MovieInfo = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(2),
  background: 'rgba(0, 0, 0, 0.7)',
  color: theme.palette.common.white,
}));

const NavigationButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
}));

// PUBLIC_INTERFACE
const MovieSlideshow = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % staticMovies.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(timer);
  }, []);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? staticMovies.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % staticMovies.length);
  };

  return (
    <SlideContainer>
      {staticMovies.map((movie, index) => (
        <Slide
          key={movie.id}
          elevation={3}
          sx={{
            transform: `translateX(${(index - currentIndex) * 100}%)`,
            display: Math.abs(index - currentIndex) > 1 ? 'none' : 'flex',
          }}
        >
          <Box
            component="img"
            src={movie.poster}
            alt={movie.title}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
          <MovieInfo>
            <Typography variant="h5" gutterBottom>
              {movie.title}
            </Typography>
            <Typography variant="body1">
              Rating: {movie.rating}/10
            </Typography>
          </MovieInfo>
        </Slide>
      ))}
      <NavigationButton
        onClick={handlePrevious}
        sx={{ left: theme => theme.spacing(2) }}
      >
        <ArrowBack />
      </NavigationButton>
      <NavigationButton
        onClick={handleNext}
        sx={{ right: theme => theme.spacing(2) }}
      >
        <ArrowForward />
      </NavigationButton>
    </SlideContainer>
  );
};

export default MovieSlideshow;
