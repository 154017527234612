import React, { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  useTheme,
  useMediaQuery,
  Box,
  Tooltip,
} from '@mui/material';
import { SidebarContext } from '../../App';
import {
  TrendingUp as TrendingIcon,
  LocalMovies as GenresIcon,
  Search as SearchIcon,
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';

// PUBLIC_INTERFACE
function Sidebar() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { isCollapsed, setIsCollapsed } = useContext(SidebarContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const location = useLocation();

  const DRAWER_WIDTH = 240;
  const COLLAPSED_WIDTH = 65;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const menuItems = [
    { text: 'Trending', icon: <TrendingIcon />, path: '/' },
    { text: 'Genres', icon: <GenresIcon />, path: '/genres' },
    { text: 'Search', icon: <SearchIcon />, path: '/search' },
  ];

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const drawer = (
    <>
      {!isMobile && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2,
            backgroundColor: theme.palette.background.sidebar,
            color: theme.palette.text.primary
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, px: 2 }}>
            {!isCollapsed && <Box sx={{ typography: 'h6' }}>Movie Explorer</Box>}
            <IconButton onClick={handleCollapse}>
              {isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </Box>
        </Box>
      )}
      <List>
        {menuItems.map((item) => (
          <Tooltip title={isCollapsed ? item.text : ''} placement="right" key={item.text}>
            <ListItem
              button
              onClick={() => {
                navigate(item.path);
                if (isMobile) {
                  handleDrawerToggle();
                }
              }}
              selected={location.pathname === item.path}
              sx={{
                justifyContent: isCollapsed ? 'center' : 'flex-start',
                px: isCollapsed ? 2 : 3,
                py: 1.5,
                '&.Mui-selected': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  }
                },
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                }
              }}
            >
              <ListItemIcon sx={{ 
                minWidth: isCollapsed ? 0 : 40,
                color: 'inherit',
                '& .MuiSvgIcon-root': {
                  fontSize: '1.5rem'
                }
              }}>
                {item.icon}
              </ListItemIcon>
              {!isCollapsed && <ListItemText primary={item.text} />}
            </ListItem>
          </Tooltip>
        ))}
      </List>
    </>
  );

  return (
    <div>
      {isMobile && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ position: 'fixed', top: 10, left: 10, zIndex: 1100 }}
        >
          <MenuIcon />
        </IconButton>
      )}
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={isMobile ? mobileOpen : true}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better mobile performance
        }}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: theme.palette.background.sidebar,
            color: theme.palette.text.primary,
            width: isMobile ? DRAWER_WIDTH : (isCollapsed ? COLLAPSED_WIDTH : DRAWER_WIDTH),
            boxSizing: 'border-box',
            top: 0,
            height: '100%',
            transition: theme.transitions.create(
              ['width', 'background-color', 'color', 'border-color', 'box-shadow'],
              {
                easing: theme.transitions.easing.easeInOut,
                duration: theme.transitions.duration.standard,
              }
            ),
            overflowX: 'hidden',
          },
          '& .MuiDrawer-paperAnchorLeft': {
            borderRight: `1px solid ${theme.palette.divider}`,
          },
        }}
      >
        {drawer}
      </Drawer>
    </div>
  );
}

export default Sidebar;
