import React from 'react';
import { Box, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import MovieSlideshow from '../MovieSlideshow';
import ThemeToggle from '../common/ThemeToggle';

const HeaderContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.header,
  marginBottom: theme.spacing(1),
  height: 'auto',
  overflow: 'hidden',
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  transition: theme.transitions.create(['background-color', 'box-shadow'], {
    duration: theme.transitions.duration.standard,
    easing: theme.transitions.easing.easeInOut,
  }),
}));

const ThemeToggleContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 1,
}));

const SlideshowContainer = styled(Container)(({ theme }) => ({
  maxWidth: '100%',
  padding: theme.spacing(1),
  height: '250px', // Default desktop height
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  [theme.breakpoints.between('sm', 'md')]: {
    height: '200px', // Tablet height
    padding: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    height: '180px', // Mobile height
    padding: theme.spacing(0.5),
  },
}));

// PUBLIC_INTERFACE
const Header = () => {
  return (
    <HeaderContainer>
      <ThemeToggleContainer>
        <ThemeToggle />
      </ThemeToggleContainer>
      <SlideshowContainer>
        <MovieSlideshow />
      </SlideshowContainer>
    </HeaderContainer>
  );
};

export default Header;
